@import '../utils/helpers/mixin.scss';

.layout {
  &__header {
    display: flex;
    align-items: center;

    .header {
      &__logo {
        display: flex;
      }

      &__account-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        background-color: #22252b;
        border-color: #22252b;
        padding: 20px 15px;
        border-radius: 30px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        strong {
          color: white;
          margin-right: 10px;
        }

        .anticon {
          color: white;
        }
      }
    }
  }

  &__body {
    min-height: calc(100vh - 64px);

    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 30px 16px;
    }

    .container-fluid {
      padding: 30px 16px;
    }
  }
}

.icon_collapsed {
  float: right;
  margin: 10px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}

.responsive_dashboard {
  z-index: 9;
  position: fixed;
  @include breakpoint(sx) {
    background: rgba(0, 0, 0, 0.25);
    width: 0vw;
    position: fixed;
    top: 0px;
    left: -200px;
    z-index: 9999;
  }

  .ant-layout-sider {
    height: 100vh;
  }
}

.ant-layout-header {
  @include breakpoint(sx) {
    padding-inline: 10px !important;
  }
}
.layout__header {
  position: fixed;
  width: 100vw;
  z-index: 999;
  @include breakpoint(sm) {
    padding-inline: unset;
    padding: 0 20px 0 54px;
  }
}
.layout__body {
  margin-top: 64px;
  height: calc(100vh - 64px) !important;
  overflow: hidden;
}
.inactiveSideBar {
  left: -200px !important;
}
.inactiveSideBar {
  left: 0px !important;
  width: 200vw;
}
.wrap_top {
  display: flex;
  column-gap: 10px;
}
.fill_button {
  svg {
    fill: #fff;
  }
}
.ant-select-clear {
  display: inline-block;
  .anticon-close-circle {
    @include breakpoint(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
