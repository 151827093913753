@mixin breakpoint($class) {
  @if $class == xs-min {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $class == xs {
    @media (max-width: 500px) {
      @content;
    }
  } @else if $class == sx {
    @media (max-width: 715px) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: 900px) {
      @content;
    }
  } @else if $class == ipad {
    @media (max-width: 1124px) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: 1250px) {
      @content;
    }
  } @else if $class == desktop {
    @media (max-width: 1500px) {
      @content;
    }
  } @else if $class == macpro {
    @media (max-width: 1790px) {
      @content;
    }
  }
}

@mixin maxWidth($size) {
  @media (max-width: $size) {
    @content;
  }
}
