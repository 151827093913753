@import '../../../utils/helpers/mixin.scss';

.checkout_container {
  overflow: overlay;
  width: 550px;

  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin: auto;
  height: calc(100vh - 64px);
  position: relative;

  @include breakpoint(sm) {
    margin-left: 28%;
  }
  @include breakpoint(ipad) {
    margin-left: 28%;
  }
  @include breakpoint(sx) {
    margin-left: unset;
    margin: auto;
  }
  @include breakpoint(xs) {
    width: 100%;
    margin: auto;
  }
  .checkout_header {
    padding-top: 10px;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background-color: #ffffff;
    z-index: 999;
    width: 550px;

    @include breakpoint(xs) {
      width: 100%;
      padding-top: 0px;
    }

    .checkout_header__title {
      color: #1890ff;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      width: 100%;
      text-align: center;
      padding: 5px 0 12px 0;
      margin-bottom: 5px;
      border-bottom: 2px solid #1890ff;

      @include breakpoint(xs) {
        margin-bottom: 0px;
        padding: 10px 0 12px 0;
      }
    }

    .checkout_header__title_err {
      color: #ca2020;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      width: 100%;
      text-align: center;
      padding: 5px 0 12px 0;
      margin-bottom: 5px;
      border-bottom: 2px solid #1890ff;

      @include breakpoint(xs) {
        margin-bottom: 0px;
        padding: 10px 0 12px 0;
      }
    }

    .steps_header {
      @include breakpoint(sx) {
        width: 100%;
        display: none;
      }

      padding: 0 25px;

      .ant-steps-item-icon {
        width: 24px;
        height: 24px;
        margin-top: 4px;

        .ant-steps-icon {
          top: -5.5px;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .ant-steps-item-title {
        font-size: 14px;
        font-weight: 500;
      }

      .ant-steps-item-title::after {
        border-top: 1px solid #bfbfbf;
      }

      //wait
      .ant-steps-item-wait {
        .ant-steps-item-icon {
          border: 1px solid rgba(0, 0, 0, 0.25);
          border-radius: 32px;
          background-color: white;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    .step1_title {
      text-transform: uppercase;
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 10px 0;
    }
  }

  .checkout_content {
    padding: 140px 20px 20px 20px;

    @include breakpoint(sx) {
      padding: 100px 15px 20px 15px;
    }
    .header_content {
      display: flex;
      flex-direction: column;
      max-width: 256px;
      width: 100%;
    }
    .step1,
    .step2 {
      .step1_title,
      .step2_title {
        text-transform: uppercase;
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin: 10px 0;
      }

      .button_export {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .switch_emmployee {
      margin-top: 10px;
      display: flex;
      align-items: baseline;

      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #8c8c8c;
        padding-left: 7px;
      }
    }

    .checkin {
      margin-top: 25px;
    }

    .select_style {
      min-width: 256px;
      margin-top: 10px;
    }

    .select_style_2 {
      width: 182px;
      margin-top: 10px;

      @include breakpoint(sx) {
        column-gap: 20px;
        width: 160px;
        margin-top: 0;
        margin-right: 0;
      }
    }

    .switchEmployee_Space {
      margin-top: 12px;
      display: flex;
      gap: 5px !important;
      flex-wrap: wrap;
      @include breakpoint(sx) {
        margin: 12px auto;
        width: 410px;
      }
      @include breakpoint(xs) {
        width: 325px;
        margin: 12px auto;
      }
      .ant-tag {
        flex-basis: calc(100% / 4);
        padding: 0;
        margin: 0;
        border: none;
        background-color: #e8e8e8;
        color: #3d3d3d;

        &.ant-tag-checkable-checked {
          background: #11b14b;
          color: white;
        }

        .item_tag {
          position: relative;
          font-family: 'Be Vietnam Pro', sans-serif !important;
          display: flex;
          align-items: center;
          flex-direction: column;
          height: 48px;
          border-radius: 3px;
          padding: 0;
          width: 119px;

          @include breakpoint(sx) {
            // width: 94%;
            width: 105px;
          }

          .tag_status {
            position: absolute;
            right: 2px;
            top: -1px;
            font-weight: 800;
            font-size: 14px;
            line-height: 20px;
            color: #ca2020 !important;
          }

          .item_tag_top {
            padding-top: 6px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
          }

          .item_tag_bottom {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
  .footerCheckin {
    margin-left: -20px;
  }
  .checkout_footer {
    position: fixed;
    bottom: 0;
    z-index: 999;
    .wrap_content {
      padding: 19px 25px;

      margin: auto;
      width: 550px;
      display: flex;
      justify-content: space-around;
      column-gap: 18px;
      background-color: white;

      @include breakpoint(xs) {
        width: 100vw;
      }

      button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        border-radius: 5px;
        border: none;
        width: 100%;
      }
    }
  }

  .text_label,
  .text_label_Oswald {
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .text_label_Oswald {
    font-family: 'Oswald', sans-serif;
    margin-top: 0;
  }
}

.wrap_form_item {
  display: flex;
  column-gap: 5px;
  justify-content: space-between;
  height: 43px;
  align-items: center;
  padding-right: 5px;

  @include breakpoint(xs) {
    padding-right: 0px;
    grid-column-gap: 0;
    column-gap: 0px;
  }

  .wrap_button_action {
    display: flex;
    column-gap: 5px;
    padding-right: 0px;
  }

  .item_panel_member {
    width: fit-content;
    max-width: 225px;

    @include breakpoint(xs) {
      width: fit-content;
    }
  }

  .wrap_button_action {
    display: flex;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .fullName {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    @include breakpoint(xs) {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .departmentName {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    @include breakpoint(xs) {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .select_parent_group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.ant-tabs {
  .ant-tabs-nav-list {
    width: 50vw;

    @include breakpoint(xs) {
      width: 100vw;
    }

    .ant-tabs-tab {
      text-align: center;
      width: 50%;

      .ant-tabs-tab-btn {
        margin: auto;
      }
    }
  }
}

.edit_staff {
  .header_list_staff_edit {
    width: 550px;
    height: 41px;
    background: #e6f7ff;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #1890ff;
    margin-left: -15px;
    margin-top: 10px;
    padding-left: 25px;

    @include breakpoint(xs) {
      width: 100vw;
    }
  }

  .wrap_form_item {
    height: unset;

    @include breakpoint(xs) {
      padding-right: 10px;
    }

    .wrap_select_staff {
      display: flex;
    }
  }

  .ant-collapse-header {
    flex-direction: row-reverse;
    align-items: center !important;
    padding: 12px 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .select_child_group,
  .select_parent_group {
    flex-direction: row;
    gap: 10px;
  }

  .select_child_group {
    display: flex;
    align-items: center;
    margin-left: 22px;
    @include breakpoint(xs) {
      margin-left: 10px;
    }
  }

  .staff_edit_tags {
    .item_tag {
      width: 77px !important;
      height: 42px !important;
      justify-content: center;

      @include breakpoint(xs) {
        width: 77px !important;
      }

      .item_tag_top {
        padding-top: 0 !important;
      }
    }
  }
}

.wrap_ButtonStatus {
  display: flex;
  align-items: center;
  .ButtonStatus {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    height: 30px;
    width: fit-content;
  }
}

.view-checked {
  display: flex;
  flex-direction: column;
  padding: 0 13px 25px;

  .select_style {
    width: 256px;
  }
}

.Button_Inactive {
  max-width: 241px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  box-shadow: none;
}

.view_check {
  .ant-form-item {
    margin-bottom: 0;
  }

  .button_submit {
    width: 164px;
    margin-top: 12px;
    border-color: #1890ff;
    color: #1890ff;
    font-weight: 600;
  }
}

body .ant-modal {
  width: fit-content !important;
  max-width: 1000px;
  @include breakpoint(ipad) {
    .ant-pagination {
      margin-top: 8px;
    }
  }
  @include breakpoint(sm) {
  }
}

.workingTime_description {
  margin-top: 10px;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #e54d3e;
}

.tag_overTime {
  background-color: #ffcc33 !important;
  color: #3d3d3d !important;
}
.tag_support {
  background-color: #8c8c8c !important;
  color: white !important;
  pointer-events: none;
}

.scrollToTop {
  border-radius: 100%;
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 15%;
}

.ant-layout-sider {
  @include breakpoint(sx) {
    // display: none;
  }
}

.select_team_goup {
  width: 75px !important;
  float: right;

  @include breakpoint(sx) {
    width: 70px !important;
  }
}

.step3_filter {
  display: flex;
  column-gap: 10px;
  @include breakpoint(sx) {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
.p_ant-modal-body {
  max-width: 340px;
  padding: 0 20px;
  @include breakpoint(sx) {
    padding: 0 20px !important;
    max-width: 275px;
  }
}
.ant-modal-close {
  margin-top: -5px;
}
.ant-modal-content {
  padding: 12px 18px !important;
  max-width: 900px;
  width: fit-content;
  @include breakpoint(ipad) {
    padding: 12px !important;
  }
  @include breakpoint(sx) {
    padding: 5px 10px 10px !important;
    max-width: 95vw;
    font-size: 13px !important;
  }
  .ant-table-cell {
    padding: 4px !important;
  }
  .wrap_modal {
    .ant-spin-container {
      max-width: 615px;
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 625px;
      padding: 0 20px;
      @include breakpoint(sx) {
        width: 90vw;
      }
      @include breakpoint(xs) {
        width: unset;
        min-height: 350px;
        padding: 10px 0 0 0 !important;
        .ant-pagination {
          margin: 8px 0 0 0 !important;
        }
      }
    }
  }
}
.scrollToTop {
  @include breakpoint(sm) {
    bottom: 7%;
    right: 1%;
  }
  @include breakpoint(ipad) {
    bottom: 7%;
    right: 1%;
  }
  @include breakpoint(sx) {
    bottom: 10%;
    right: 0%;
  }
}
.text_title {
  text-align: center;
  border-bottom: 2px solid #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: -10px;
  color: #1890ff;
  margin-top: 15px;
  padding-bottom: 8px;
}
.timekeeping_none {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: -10px;
  color: #1890ff;
  margin-top: 15px;
  padding-bottom: 8px;
}
.tag_salon_support {
  position: absolute;
  top: 25px;
  font-size: 10px;
}
.wrap_Button_Status {
  width: fit-content;
  display: flex;
  column-gap: 5px;
  align-items: center;
}
.item_expend {
  display: flex;
  align-items: center;
  column-gap: 5px;

  .overtime {
    background-color: #fffdeb;
    border-radius: 16px;
    padding: 5.5px 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #fabd05;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    @include breakpoint(sx) {
      font-size: 14px;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  .group_team {
    color: #fff1f0;
    border-radius: 16px;
    padding: 5.5px 5px;
    background: #766cd6;
    @include breakpoint(sx) {
      font-size: 12px;
      padding: 6.5px 5px;
      height: 30px;

      // display: none;
    }
  }
}
.ant-collapse-item {
  // width: 530px;
}
.text_updateTime {
  position: absolute;
  margin-left: 38px;
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #262626;
  bottom: -8px;
}
.ant-collapse-expand-icon {
}
.disable_tab .ant-tabs-nav {
  width: 52vw;
  @include breakpoint(sx) {
    width: 140vw;
  }
}
.ant-btn {
  @include breakpoint(sx) {
    padding: 0px 8px;
  }
}
.labelEdit {
  @include breakpoint(sx) {
    display: none !important;
  }
}
.ant-collapse-expand-icon {
  @include breakpoint(sx) {
    padding-inline-end: unset !important;
  }
}
.searchByNameId {
  margin-top: 10px;
  max-width: 375px;
  @include breakpoint(sx) {
    max-width: 330px;
    width: 90vw;
  }
}
.select_child_group {
  margin-left: 10px;
}
.fullName_wrap {
  margin-left: 15px;
  @include breakpoint(sx) {
    margin-left: 5px;
  }
}
.accountName {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header__account-button img {
  @include breakpoint(sx) {
    margin-right: 0 !important;
  }
}
.ant-table-content {
  @include breakpoint(sx) {
    thead,
    tbody {
      font-size: 12px;
    }
  }
}

.isMobile {
  display: none;
  @include breakpoint(sx) {
    display: flex;
  }
}
.isNotMobile {
  display: flex;
  @include breakpoint(xs) {
    display: none !important;
  }
}
.logo_mobile {
  margin: 8px auto 0 auto;
  display: block;
  width: fit-content;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-pagination {
  @include breakpoint(xs-min) {
    flex-wrap: unset !important;
    li {
      min-width: 25px;
      height: 25px;
      margin-inline-end: 0 !important;
    }
  }
}
.removeIcon {
  .ant-picker-clear {
    display: none !important;
  }
}
