@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;300;400;500;600;700;800&family=Oswald:wght@400;500;600;700&display=swap');
body {
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: 400;
  iframe {
    display: none !important;
  }
  .ant-layout {
    height: 100vh;
    overflow: hidden;
  }
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.f-oswald {
  font-family: 'Oswald', sans-serif;
}
.wrapper {
  background-color: #ffffff;
  padding: 16px;
}
.warning-color {
  color: #fa8c16;
}
.cancel-color {
  color: #fc625d;
}
.required-color {
  color: #e54d3e;
}
.w-full {
  width: 100%;
}
.text-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.ant-btn a {
  color: #ffffff;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c1c1c1;
}
.activeIconClear {
  .ant-select-clear {
    opacity: 1 !important;
    width: 24px;
    display: flex;
    justify-content: center;
    height: 24px;
    align-items: center;
    right: 2%;
    top: 30%;
    z-index: 999;
  }
}

.icon_clear {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #bfbfbf;
  font-size: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: absolute;
}
.buttonLikeAnt {
  font-weight: 600;
  cursor: pointer;
  background-color: #1677ff;
  color: white;
  &.buttonLikeAnt_danger {
    background-color: #ff4d4f;
  }
}
.buttonLikeAnt:hover {
  opacity: 0.8;
}
